import React, { useState } from 'react';
import getConfig from 'next/config';

import Typography from 'components/ui/Typography';
import styles from './Newsletter.module.css';

const Newsletter: React.FC = () => {
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' }>();
  const [submitting, setSubmitting] = useState(false);

  return (
    <article className={styles.root}>
      <Typography component="h2" className={styles.title}>
        NYHETSBREV
      </Typography>
      <Typography component="p" className={styles.topText}>
        Registrera dig för vårt nyhetsbrev. Bli först med att få tillgång till Arkivets nyheter och
        erbjudanden.
      </Typography>

      <form
        name="Email Subscription"
        id="email-subscription"
        aria-label="Newsletter subscription"
        className={styles.form}
        onSubmit={async e => {
          e.preventDefault();

          const form = e.target as HTMLFormElement;
          const data = new FormData(form);
          const email = data.get('email') as string;
          const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

          if (isValidEmail) {
            setSubmitting(true);

            try {
              const { publicRuntimeConfig } = getConfig();
              const url = `${publicRuntimeConfig.bcom.baseUrl}/api/v1/${publicRuntimeConfig.bcom.siteCode}/newsletter/subscribe/`;

              await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
              });

              setMessage({
                type: 'success',
                text: 'Tack för att du prenumererar!',
              });

              form.reset();
            } catch {
              setMessage({
                type: 'error',
                text: 'Ett fel uppstod. Var vänlig försök igen lite senare.',
              });
            }
          } else {
            setMessage({
              type: 'error',
              text: 'Vänligen ange en giltig E-postadress.',
            });
          }

          setSubmitting(false);
        }}
      >
        <section className={styles.inputWithButton}>
          <input
            name="email"
            type="email"
            disabled={submitting}
            autoComplete="email"
            aria-label="Email address for newsletter subscription"
            className={styles.input}
            placeholder="E-postadress"
          />
          <button
            type="submit"
            disabled={submitting}
            aria-label="Subscribe to newsletter"
            className={styles.button}
          >
            <Typography variant="b">Registrera</Typography>
          </button>
        </section>

        {message != null && (
          <Typography
            component="p"
            aria-label="Newsletter feedback"
            className={styles.message}
            data-color={message.type}
          >
            {message.text}
          </Typography>
        )}
      </form>
    </article>
  );
};

export default Newsletter;
